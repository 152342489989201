<template>
  <Card>
    <template #header>
      <div
        class="d-flex align-items-center justify-content-between"
        style="width: 100%"
      >
        <div>
          <h4>Yeni Varlık</h4>
        </div>
      </div>
    </template>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <div class="row">
        <!-- sahibi -->
        <div class="col-12 mt-5">
          <div class="from-group">
            <MultiSelect
              v-model="owner"
              :options="sellers"
              :multiple="false"
              label="Sahibi"
              placeholder="Sahip Seçiniz..."
            />
          </div>
        </div>

        <br />

        <div class="col-12">
          <div class="card-sub-header">Gayrimenkul Bilgileri</div>
        </div>

        <!-- ad -->
        <div class="col-12">
          <b-form-group class="add-style">
            <label for="ad">Adı </label>
            <b-form-input v-model="name" name="name" id="ad" required />
          </b-form-group>
        </div>
        <!-- izlenecek -->
        <div class="col-4">
          <div class="form-group">
            <label for="izlenecek">İzlenecek </label>
            <select
              v-model="watch"
              name="watch"
              class="form-control"
              id="exampleFormControlSelect1"
            >
              <option value="" selected>Seçiniz..</option>
              <option value="1">Evet</option>
              <option value="0">Hayır</option>
            </select>
          </div>
        </div>
        <!-- durum -->
        <div class="col-4">
          <div class="form-group">
            <label for="durum">Durumu </label>
            <select
              name="status"
              v-model="status"
              class="form-control"
              id="exampleFormControlSelect1"
            >
              <option value="" selected>Seçiniz..</option>
              <option value="1">Aktif</option>
              <option value="0">Pasif</option>
              <option value="2">Satıldı</option>
            </select>
          </div>
        </div>
        <!-- banka gayrimenkul -->
        <div class="col-4">
          <b-form-group class="add-style">
            <label for="vergiNo">Banka Gayrimenkul Kodu </label>
            <b-form-input
              name="bank_propertycode"
              v-model="bank_propertycode"
              id="vergiNo"
              required
            />
          </b-form-group>
        </div>

        <br />

        <!-- konum bilgileri -->
        <div class="col-12">
          <div class="card-sub-header">Konum Bilgileri</div>
        </div>
      </div>
      <div class="row mt-5">
        <!-- il -->
        <div class="col-6">
          <div class="form-group">
            <MultiSelect
              :key="counties.length"
              v-model="providence"
              :options="counties"
              :multiple="false"
              label="İl"
              placeholder="İl Seçiniz"
              id="il"
            />
          </div>
        </div>
        <!-- ilçe -->
        <div class="col-6">
          <div class="form-group">
            <MultiSelect
              :key="districts.length"
              v-model="district"
              :options="districts"
              :multiple="false"
              label="İlçe"
              placeholder="İlçe Seçiniz"
              id="ilce"
            />
          </div>
        </div>
        <div class="col-6">
          <b-form-group class="add-style">
            <MultiSelect
              :key="neighbourhoods.length"
              v-model="neighbourhood"
              :options="neighbourhoods"
              :multiple="false"
              label="Mahalle"
              placeholder="Mahalle Seçiniz"
              id="mahalle"
            />
          </b-form-group>
        </div>
        <!-- cadde -->
        <div class="col-6">
          <b-form-group class="add-style">
            <label for="cadde">Cadde </label>
            <b-form-input id="cadde" v-model="avenue" name="avenue" />
          </b-form-group>
        </div>
        <!-- sokak -->
        <div class="col-6">
          <b-form-group class="add-style">
            <label for="sokak">Sokak </label>
            <b-form-input id="sokak" v-model="street" name="street" />
          </b-form-group>
        </div>
        <!-- Dış kapı no -->
        <div class="col-6">
          <b-form-group class="add-style">
            <label for="kapiNumarasi">Dış Kapı Numarası </label>
            <b-form-input
              id="kapiNumarasi"
              name="outdoor_number"
              v-model="outdoor_number"
              required
            />
          </b-form-group>
        </div>
        <!-- İç kapı no -->
        <div class="col-6">
          <b-form-group class="add-style">
            <label for="icKapiNo">İç Kapı Numarası </label>
            <b-form-input
              id="icKapiNo"
              name="intdoor_number"
              v-model="intdoor_number"
              required
            />
          </b-form-group>
        </div>
        <!-- Adres-->
        <div class="col-12">
          <b-form-group class="add-style">
            <label for="adres">Adres </label>
            <b-form-input name="address" v-model="address" id="ad" />
          </b-form-group>
        </div>
      </div>

      <br />

      <!-- harita bilgileri -->
      <div class="row">
        <div class="col-12">
          <div class="card-sub-header">Harita Bilgileri</div>
        </div>
        <!-- enlem -->
        <div class="col-6">
          <b-form-group class="add-style">
            <label for="enlem">Enlem </label>
            <b-form-input
              name="latitude"
              v-model="latitude"
              id="enlem"
              required
            />
          </b-form-group>
        </div>
        <!-- boylam -->
        <div class="col-6">
          <b-form-group class="add-style">
            <label for="boylam">Boylam </label>
            <b-form-input
              name="longitude"
              v-model="longitude"
              id="boylam"
              required
            />
          </b-form-group>
        </div>
      </div>

      <br />

      <!-- tapu bilgileri -->
      <div class="row">
        <div class="col-12">
          <div class="card-sub-header">Tapu Bilgileri</div>
        </div>
        <div class="col-6">
          <div class="from-group">
            <MultiSelect
              v-model="actualuse_purpose"
              name="actualuse_purpose"
              :options="purposes"
              :multiple="false"
              label="Filli Kullanım Niteliği"
              placeholder="Fiili Kullanım Niteliği Seçiniz"
              id="purpose"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <MultiSelect
              v-model="actualuse_attribute"
              name="actualuse_attribute"
              :options="usages"
              :multiple="false"
              label="Filli Kullanım Amacı"
              placeholder="Fiili Kullanım Amacı Seçiniz"
              id="usage"
            />
          </div>
        </div>
        <div class="col-4">
          <b-form-group class="add-style">
            <label for="adet">Adet </label>
            <b-form-input name="piece" v-model="piece" id="adet" required />
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group class="add-style">
            <label for="ada">Ada </label>
            <b-form-input name="island" v-model="island" id="ada" required />
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group class="add-style">
            <label for="parsel">Parsel </label>
            <b-form-input name="parcel" v-model="parcel" id="parsel" required />
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group class="add-style">
            <label for="bagimsizBolumNo">Bağımsız Bölüm No </label>
            <b-form-input
              name="independent_sectionno"
              v-model="independent_sectionno"
              id="bagimsizBolumNo"
              required
            />
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group class="add-style">
            <label for="tapuTescilTarihi">Tapu Tescil Tarihi </label>
            <b-form-input
              name="deedregistration_date"
              v-model="deedregistration_date"
              :type="'date'"
              id="tapuTescilTarihi"
              required
            />
          </b-form-group>
        </div>
      </div>

      <!-- tapu bilgileri -->
      <div class="col-12">
        <div class="card-sub-header">Malikler</div>
      </div>
      <div class="row" v-for="i in malikCount" :id="'malik-' + i" :key="i">
        <!-- trash icon -->
        <div class="col-12 d-flex">
          <div
            class="col-1"
            style="
              max-width: 8.33333333%;
              justify-content: center;
              align-items: center;
              display: flex;
            "
          >
            <b-button variant="light" @click="deleteRow(i)">
              <i class="flaticon2-trash p-0"></i>
            </b-button>
          </div>
          <!-- hissedar -->
          <div class="col">
            <div class="from-group">
              <MultiSelect
                v-model="owners[i - 1].actualuse_attribute"
                :options="sellers"
                :multiple="false"
                label="Hissedar"
                placeholder="Hissedar Seçiniz"
                :id="'hissedar-' + i"
              />
            </div>
          </div>
          <!-- hisse pay -->
          <div class="col">
            <b-form-group class="add-style">
              <label for="pay">Hisse (Pay) </label>
              <b-form-input v-model="owners[i - 1].share" id="pay" required />
            </b-form-group>
          </div>
          <!-- hisse pay -->
          <div class="col">
            <b-form-group class="add-style">
              <label for="payda">Hisse (Payda) </label>
              <b-form-input
                v-model="owners[i - 1].denominator"
                id="payda"
                required
              />
            </b-form-group>
          </div>
        </div>
      </div>

      <div class="col-3">
        <b-button variant="light" @click="malikEkle">
          Malik Ekle
          <i class="flaticon2-plus p-0 ml-2"></i>
        </b-button>
      </div>

      <div class="row">
        <b-button
          variant="primary"
          class="px-6 py-4 my-5 font-weight-bolder mx-auto"
          ref="submitButton"
          type="submit"
          >KAYDET
        </b-button>
      </div>
    </b-form>
  </Card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import { SELLERS } from "@/core/services/store/seller/seller.module.js";
import { PURPOSE, USAGE } from "@/core/services/store/usage.module";
import {
  COUNTIES,
  DISTRICTS,
  NEIGHBOURHOODS,
} from "@/core/services/store/counties.module.js";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("tr");

export default {
  name: "new-property",
  mixins: [validationMixin],

  data() {
    return {
      districts: [],
      neighbourhoods: [],
      sellers: [],
      purposes: [],
      usages: [],
      counties: [],
      malikCount: 1,
      owner: "",
      name: "",
      watch: "",
      status: "",
      bank_propertycode: "",
      providence: "",
      district: "",
      neighbourhood: "",
      avenue: "",
      street: "",
      outdoor_number: "",
      intdoor_number: "",
      address: "",
      latitude: "",
      longitude: "",
      piece: "",
      actualuse_attribute: "",
      actualuse_purpose: "",
      island: "",
      parcel: "",
      independent_sectionno: "",
      deedregistration_date: "",
      owners: [
        {
          actualuse_attribute: "",
          share: "",
          denominator: "",
        },
      ],
    };
  },
  created() {
    this.countiesLoading = true;
    this.$store.dispatch(COUNTIES);
    this.$store.dispatch(SELLERS, {myUser:this.myUser,query:""});
    this.$store.dispatch(PURPOSE, this.myUser);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Varlıklar / Yeni", route: "/properties/new" },
    ]);
  },
  methods: {
    malikEkle() {
      this.malikCount += 1;
      this.owners.push({
        actualuse_attribute: "",
        share: "",
        denominator: "",
      });
    },
    deleteRow(i) {
      let row = document.getElementById("malik-" + i);
      row.remove();
      this.owners.splice(i - 1, 1);
    },
    onSubmit() {
      const select = document.querySelectorAll("select");
      select.forEach((element) => {
        if (element.value.length === 0) {
          element.classList.add("is-invalid");
          element.focus();
          return;
        }
      });
      let submitButton = this.$refs["submitButton"];
      this.$spinnerLoader.addSpinnerLoader(submitButton);

      this.addProperty()
        .then((res) => {
          if (res.status.code === 200) {
            this.$generateNotification(
              this,
              "success",
              "Varlık Başarıyla Eklenmiştir."
            );
          } else {
            this.$generateNotification(this, "error", res.status.message);
          }
        }).catch((res)=>{
          this.$generateNotification(this, "error", res.status.message);
        })
        .finally(() => {
          this.readOnly = false;
          this.$spinnerLoader.removeSpinnerLoader(submitButton);
        });
    },
    addProperty() {
      const ownersArray = this.owners.map((item) => {
        return {
          share: item.share,
          denominator: item.denominator,
          actualuse_attribute: item.actualuse_attribute.value,
        };
      });

      let formData = {
        user_token: this.myUser,
        owner: this.owner.value,
        name: this.name,
        watch: this.watch,
        status: this.status,
        bank_propertycode: this.bank_propertycode,
        province: this.providence.value,
        district: this.district.value,
        neighbourhood: this.neighbourhood.value,
        avenue: this.avenue,
        street: this.street,
        outdoor_number: this.outdoor_number,
        intdoor_number: this.intdoor_number,
        address: this.address,
        latitude: this.latitude,
        longitude: this.longitude,
        piece: this.piece,
        actualuse_attribute: this.actualuse_attribute.value,
        actualuse_purpose: this.actualuse_purpose.value,
        island: this.island,
        parcel: this.parcel,
        independent_sectionno: this.independent_sectionno,
        instep:1,
        deedregistration_date: moment(
          new Date(this.deedregistration_date)
        ).format("DD/MM/YYYY"),
      };
      const formDataSend = new FormData()
      for (const key in formData) {
        formDataSend.append(key, formData[key]);
      }
      ownersArray.forEach((owner, index) => {
        formDataSend.append(`owners[${index}][share]`, owner.share);
        formDataSend.append(`owners[${index}][denominator]`, owner.denominator);
        formDataSend.append(`owners[${index}][actualuse_attribute]`, owner.actualuse_attribute);
      });


      return new Promise((resolve, reject) => {
        this.$ApiService
          .post('property/property', formDataSend)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });

        });
    },
    onProvinceChange() {
      if (this.providence) {
        this.$store
          .dispatch(DISTRICTS, this.providence.value)
          .then((districts) => {
            const newDistricts = this.setDistrictsSelectValues(districts);
            this.districts = newDistricts;
          });
      } else {
        this.districts = [];
      }
    },
    onDistrictChange() {
      if (this.district) {
        const payload = {
          il_id: this.providence.value,
          ilce_id: this.district.value,
        };
        this.$store.dispatch(NEIGHBOURHOODS, payload).then((street) => {
          const newNeighbourhoods = this.setNeighbourhoodsSelectValues(street);
          this.neighbourhoods = newNeighbourhoods;
        });
      } else {
        this.newNeighbourhoods = [];
      }
    },
    onPurposeChange() {
      if (this.actualuse_purpose) {
        const payload = {
          user_token: this.myUser,
          purpose_id: this.actualuse_purpose.value,
        };
        this.$store.dispatch(USAGE, payload);
      }
    },
    setCountiesSelectValues(county) {
      if (county?.length > 0) {
        const newCounties = county.map((index, key) => {
          return { text: index.il_adi, value: index.il_id };
        });
        this.counties = newCounties;
      }

      this.countiesLoading = false;
    },
    setDistrictsSelectValues(districts) {
      const setDistrictsSelect = districts.map((index, key) => {
        return { text: index.ilce_adi, value: index.ilce_id };
      });
      return setDistrictsSelect;
    },
    setNeighbourhoodsSelectValues(neighbourhoods) {
      const setNeighbourhoodsSelect = neighbourhoods.map((index, key) => {
        return { value: index.mahalle_id, text: index.mahalle_adi };
      });
      return setNeighbourhoodsSelect;
    },
    setSellerSelectValues(sellers) {
      const setSellerSelect = sellers.map((index, key) => {
        return { value: index._id.$oid, text: index.title };
      });
      return setSellerSelect;
    },
    setPurposeSelectValue(purposes) {
      const setPurposesSelect = purposes.map((index, key) => {
        return { value: index._id.$oid, text: index.name };
      });
      return setPurposesSelect;
    },
    setUsageSelectValue(usage) {
      const setUsagesSelect = usage.map((index, key) => {
        return { value: index._id.$oid, text: index.name };
      });
      return setUsagesSelect;
    },
  },
  watch: {
    getCounties(newCounties) {
      if (newCounties.length > 0) {
        this.setCountiesSelectValues(newCounties);
      }
    },
    providence: "onProvinceChange",
    district: "onDistrictChange",
    actualuse_purpose: "onPurposeChange",
    getSellers(newSellers) {
      this.sellers = this.setSellerSelectValues(newSellers);
    },
    getPurpose(newPurpose) {
      this.purposes = this.setPurposeSelectValue(newPurpose);
    },
    getUsage(newUsage) {
      this.usages = this.setUsageSelectValue(newUsage);
    },
    // deedregistration_date:function(){
    //   console.log( moment(new Date(this.deedregistration_date)).format("DD/MM/YYYY"))
    // }
  },
  computed: {
    ...mapGetters([
      "getCounties",
      "getSellers",
      "myUser",
      "getPurpose",
      "getUsage",
    ]),
  },
};
</script>
